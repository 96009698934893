/*-- -------------------------- -->
<---           Hero             -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #hero-229 {
      /* changes on tablet */
      padding: 0 1rem;
      position: relative;
      z-index: 1;
      /* prevents overflow from the lines extending past the screen width */
      overflow: hidden;
    }
    #hero-229 .cs-picture {
      /* Background Image */
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
    }
    #hero-229 .cs-picture:before {
      /* Black Color Overlay */
      content: '';
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.6;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      z-index: 1;
      /* prevents the cursor from interacting with it */
      pointer-events: none;
    }
    #hero-229 .cs-picture img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      /* Makes image act like a background-image */
      object-fit: cover;
    }
    #hero-229 .cs-container {
      width: 100%;
      max-width: 80rem;
      /* we put the padding top and bottom on the container instead of #Hero so the pseudo element lines go to the top and bottom of the section */
      /* 144px - 280px - leaving extra space for the navigation */
      /* changes on tablet */
      padding: clamp(9rem, 0.95vw, 17.5rem) 0;
      margin: auto;
      position: relative;
    }
    /* #hero-229 .cs-container:before {
      content: '';
      width: 1px;
      height: 100%;
      background: -moz-linear-gradient(top, rgba(250, 251, 252, 0.5) 0%, rgba(250, 251, 252, 0) 100%);
      background: -webkit-linear-gradient(top, rgba(250, 251, 252, 0.5) 0%, rgba(250, 251, 252, 0) 100%);
      opacity: 1;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
    } */
    #hero-229 .cs-flex-group {
      /* 60px - 220px */
      margin-bottom: clamp(3.75rem, 15.5vw, 13.75rem);
      margin: auto;
      width: 80vw;
      /* 464px - 562px */
      /* max-width: clamp(29rem, 60vw, 35.125rem); */
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 1.25rem;
      box-sizing: border-box;
    }
    #hero-229 .cs-topper {
      /* 13px - 16px */
      font-size: clamp(0.8125rem, 1.6vw, 1rem);
      line-height: 1.2em;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.1rem;
      font-weight: 700;
      /* color: var(--primary); */
      color: white;
      margin-bottom: 1rem;
      display: block;
    }
    #hero-229 .cs-title {
      /* 39px - 61px */
      /* font-size: clamp(2.4375rem, 6.4vw, 3.8125rem); */
      font-size: 50px;
      font-weight: 900;
      line-height: 1.2em;
      text-align: center;
      width: 100%;
      /* 32px - 40px */
      /* margin: 0 auto clamp(2rem, 4vw, 2.5rem) 0; */
      color: var(--bodyTextColorWhite);
      position: relative;

    }
    #hero-229 .cs-text {
      /* 16px - 20px */
      /* font-size: clamp(1rem, 1.95vw, 1.25rem); */
        font-size: 18px;
      line-height: 1.5em;
      text-align: center;
      width: 100%;
      /* 32px - 40px */
        margin:auto;
      /* 40px - 48px */
      margin-bottom: clamp(2.5rem, 4vw, 3rem);
      color: var(--bodyTextColorWhite);
      font-weight: 500;
      letter-spacing: 0.5px;
    }
    #hero-229 .cs-button-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /* 16px - 20px */
      gap: clamp(1rem, 2.3vw, 1.25rem);
    }
    #hero-229 .cs-button {
      min-width: 12.3125rem;
    }
    #hero-229 .cs-button-solid {
      font-size: 1rem;
      font-weight: 700;
      /* 46px - 56px */
      line-height: clamp(2.875em, 5.5vw, 3.5em);
      text-align: center;
      text-decoration: none;
      margin: 0;
      /* prevents padding from adding to the width */
      box-sizing: border-box;
      padding: 0 3rem;
      background-color: var(--primary);
      overflow: hidden;
      color: #fff;
      border: none;
      display: inline-block;
      position: relative;
      z-index: 1;
      transition: color 0.3s;
    }
    #hero-229 .cs-button-solid:before {
      content: "";
      width: 0;
      height: 100%;
      background: #000;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: width 0.3s;
    }
    #hero-229 .cs-button-solid:hover {
      color: #fff;
    }
    #hero-229 .cs-button-solid:hover:before {
      width: 100%;
    }
    #hero-229 .cs-button-transparent {
      font-size: 1rem;
      /* 46px - 56px */
      line-height: clamp(2.875rem, 5.5vw, 3.5rem);
      width: 11.25rem;
      /* 46px - 56px */
      height: clamp(2.875rem, 5.5vw, 3.5rem);
      text-decoration: none;
      font-weight: 700;
      margin: 0;
      color: #fff;
      padding: 0;
      background-color: transparent;
      border: 1px solid var(--bodyTextColorWhite);
      box-sizing: border-box;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;
    }
    #hero-229 .cs-button-transparent:before {
      content: '';
      position: absolute;
      display: block;
      background: #000;
      opacity: 1;
      /* so it sits on top of the border */
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      z-index: -1;
      transform-origin: left;
      /* this is what creates the grow affect on hover */
      transform: scaleX(0);
      transition: transform 0.3s;
    }
    #hero-229 .cs-button-transparent:hover:before {
      transform: scaleX(1);
    }
    #hero-229 .cs-button-transparent .cs-img {
      display: block;
      margin-right: 0.75rem;
    }
    .cs-email-group {
      flex-direction: column;
      align-items: center;
    }
  }
  /* Tablet - 768px */
  @media only screen and (min-width: 48rem) {
    #hero-229 {
      /* 32px - 40px */
      padding: 0 clamp(2rem, 5vw, 2.5rem);
    }
    /* #hero-229 .cs-container:after {
      content: '';
      width: 1px;
      height: 100%;
      background: -moz-linear-gradient(top, rgba(250, 251, 252, 0) 0%, rgba(250, 251, 252, 0.5) 100%);
      background: -webkit-linear-gradient(top, rgba(250, 251, 252, 0) 0%, rgba(250, 251, 252, 0.5) 100%);
      opacity: 1;
      position: absolute;
      display: block;
      top: 0;
      right: 0;
    } */
    #hero-229 .cs-button-group {
      flex-direction: row;
    }
    #hero-229 .cs-title {
      /* 39px - 61px */
      /* font-size: clamp(2.4375rem, 6.4vw, 3.8125rem); */
      font-size: 100px;
      font-weight: 900;
      line-height: 1.2em;
      text-align: center;
      width: 100%;
      /* 32px - 40px */
      /* margin: 0 auto clamp(2rem, 4vw, 2.5rem) 0; */
      color: var(--bodyTextColorWhite);
      position: relative;

    }
    #hero-229 .cs-text {
      /* 16px - 20px */
      /* font-size: clamp(1rem, 1.95vw, 1.25rem); */
        font-size: 23px;
    }
    .cs-email-group {
      flex-direction: row;
      align-items: start;
    }
  }
  /* Desktop Parallax Effect - 1300px */
  @media only screen and (min-width: 81.25rem) {
    #hero-229 {
      background: url("/public/heroimage.webp");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      /* creates parallax effect on background image */
      background-attachment: fixed;
      /* remove img tag so we can make parallax work */
    }
    #hero-229 .cs-picture img {
      display: none;
    }
  }
                                  

/* Hero Section */
#hero-229 {
    position: relative;
    color: #ffffff;
    text-align: center;
    padding: 60px 20px;
  }
  
  .cs-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .cs-flex-group {
    position: relative;
    z-index: 2;
    /* max-width: 600px; */
    margin: 0 auto;
  }
  
  .cs-topper {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .cs-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .cs-text {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  /* Email Input Group */
  .cs-email-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .cs-email-input {
    padding: 17px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 350px;
    color: #000;
    margin-top: 1px;
  }
  
  .cs-button-submit {
    padding: 19px 20px;
    font-size: 16px;
    background-color: var(--primary);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 700;
    text-decoration: none;
  }
  
  .cs-button-submit:hover {
    background-color: #555;
  }
  
  /* Background Image Styling */
  .cs-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }
  
  .cs-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
  }
  